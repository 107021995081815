$white: #FFFFFF;
$black: #000000;

$primaryColor: #4710B4;
$secondaryColor: #9353FA;
$baseColor: #1B1B1B;
$baseColor2: #FF9D3A;
$baseColor3: #23262D;
$baseColor4: #767676;
$neutralColor: #FF6386;



