@import "variables.scss";
@import "mixins.scss";

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,800&family=Electrolize&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,800&family=Electrolize&family=Open+Sans:wght@400;700&display=swap");

$primaryFont: "Electrolize", sans-serif;
$secondaryFont: "Open Sans", sans-serif;

* {
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: normal;
  text-decoration: none;
}
.align-center {
  align-items: center;
}
.primary-btn {
  @include primaryBtn;
}

.title {
  @include Title;
  margin: 0;
}

.des {
  @include Des;
}

.header {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 999;
  background: $white;
  border-bottom: 1px solid #d6ebf7;
  .container {
    .header_cnt {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      .logo {
        font-family: $primaryFont;
        font-size: 28px;
        color: $baseColor2;
        font-weight: 400;
        text-decoration: none;
        img {
          display: block;
          width: 220px;
        }
      }
      nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        ul {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0;
          margin: 0;
          position: relative;
          li {
            margin-left: 40px;

            a {
              color: $baseColor3;
              font-family: $secondaryFont;
              font-size: 14px;
              font-weight: 400;
              text-decoration: none;
              align-items: center;
              display: inline-flex !important;
              border-bottom: 1px solid transparent;
              line-height: 32px;
              svg {
                width: 20px;
                margin-left: 2px;
              }

              &.contact {
                background: transparent
                  linear-gradient(205deg, #4114ad 0%, #ed6c85 100%) 0% 0%
                  no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                height: 34px;
                width: 90px;
                border-radius: 4px;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            &:nth-child(1) {
              margin-left: 0px;
            }
            &:hover {
              a.sub {
                color: #ff6386;
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;
                svg path {
                  fill: #ff6386;
                }
                &:after {
                  content: "";
                  position: absolute;
                  left: 0px;
                  bottom: 0px;
                  width: 100%;
                  height: 1px;
                  background: #ff6386;
                }
              }
              .submenu {
                display: flex;
                @media screen and (max-width: 767px) {
                  display: block;
                }
              }
            }
            .submenu {
              position: absolute;
              top: 100%;
              right: 0px;
              box-shadow: 0px 4px 10px #00000029;
              border-radius: 40px;
              background: #ffffff;
              display: flex;
              padding: 30px 50px;
              width: 700px;
              justify-content: space-between;
              display: none;

              h4 {
                color: #ff6386;
                font-family: $secondaryFont;
                font-size: 22px;
                line-height: 29px;
                a {
                  color: #ff6386;
                  font-family: $secondaryFont;
                  font-size: 22px;
                  line-height: 29px;
                }
              }
              ul {
                display: block;
                li {
                  margin: 0px;
                  padding: 6px 0;
                  a {
                    padding: 0 0 0 14px;
                    font-family: $secondaryFont;
                    font-size: 16px;
                    color: $baseColor;
                    &:hover {
                      background: url("../images/arrow.png") no-repeat left
                        center;
                      background-size: auto 16px;
                      color: #9353fa;
                    }
                  }
                }
              }
              @media screen and (max-width: 767px) {
                width: 100%;
                left: 0;
                right: inherit;
                border-radius: 10px;
                padding: 20px;
                h4 {
                  font-size: 16px;
                  line-height: 16px;
                  a {
                    font-size: 16px;
                    line-height: 20px;
                    text-align: left;
                  }
                }
                ul {
                  li {
                    a {
                      text-align: left;
                      font-size: 12px;
                      line-height: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      @media screen and (max-width: 767px) {
        nav ul li {
          margin-left: 20px;
        }
      }
      @media screen and (max-width: 565px) {
        height: 60px;
        .logo {
          img {
            width: 90px;
          }
        }
        nav ul li {
          margin-left: 10px;
          &:nth-child(1) {
            margin-left: 0px;
          }
          a {
            font-size: 12px;
            display: block;
            text-align: right;
            line-height: 14px;
            &.contact {
              width: 75px;
              height: 28px;
            }
          }
        }
      }
    }
  }
}
body.home-page {
  .header {
    background: transparent linear-gradient(116deg, #e8fbf5 0%, #d6ecf8 100%);
    border-bottom: 0px;
  }
}
.home-banner {
  background: transparent linear-gradient(116deg, #e8fbf5 0%, #d6ecf8 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 80px;
  .banner-cnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .banner-text {
      width: 450px;
      h2 {
        @include Title;
      }
      p {
        font-family: $secondaryFont;
        font-size: 22px;
        color: $baseColor3;
        font-weight: 400;
      }
      .cta {
        @include primaryBtn;
        margin: 0px 0px 0px 0px;
      }
    }
    figure {
      width: calc(100% - 450px);
      text-align: right;
      img {
        max-width: 100%;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 30px 0;
      .banner-text {
        width: 100%;
        h2 {
          font-size: 26px;
        }
        p {
          font-size: 16px;
        }
      }
      figure {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 575px) {
    padding-top: 60px;
  }
}
.Bundle_section {
  padding: 68px 15px 32px;
  .Bundle_container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .Bundle_text {
      width: 50%;
      h2 {
        @include Title;
      }
      h3 {
        font-family: $secondaryFont;
        font-size: 20px;
        font-weight: 400;
        margin: 0px 0px 28px 0px;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          margin: 0px 0px 20px 0px;
        }
      }
      ul {
        padding: 0px;
        margin: 0px;
        li {
          font-family: $secondaryFont;
          font-size: 22px;
          color: $baseColor;
          font-weight: 400;
          display: flex;
          align-items: center;
          margin: 0px 0px 18px 0px;
          background: url("../images/checked.png") no-repeat center left;
          padding: 0 0 0 28px;
          img {
            width: 31px;
            height: 31px;
            margin-right: 19px;
          }
          &:last-child {
            margin: 0px;
          }
          @media screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
      }
    }
    .Bundle_banner {
      text-align: center;
      position: relative;
      padding-bottom: 30px;
      width: 50%;
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: url("../images/shape_1.png") no-repeat 0 0;
        display: block;
        position: absolute;
        bottom: 0;
        right: 105px;
        z-index: -1;
      }
      figure {
        position: relative;
        z-index: 9;
        margin: 0;
        img {
          max-width: 100%;
        }
      }
      .action {
        position: relative;
        z-index: 9;
        @include primaryBtn;
        margin: 40px 0px 0px 0px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 30px 15px;
    .Bundle_container {
      flex-direction: column;
      .Bundle_text {
        width: 100%;
        padding-bottom: 30px;
      }
      .Bundle_banner {
        width: 100%;
        padding-top: 30px;
        &:after {
          right: 0px;
          background-position: center bottom;
        }
      }
    }
  }
}

.Bundle_content {
  padding: 100px 0px 87px 0px;
  background: transparent linear-gradient(122deg, #fbfefd 0%, #f4fafd 100%);
  .banner_section {
    display: flex;
    align-items: center;

    figure {
      width: 530px;
      img {
        width: 100%;
      }
    }
    .banner_content {
      width: calc(100% - 531px);
      h2 {
        @include Title;
      }
      p {
        font-family: $secondaryFont;
        font-size: 22px;
        color: $baseColor;
        font-weight: 400;
      }
    }
    @media screen and (max-width: 991px) {
      flex-direction: column;
      .banner_content {
        width: 100%;
      }
      figure {
        width: 100%;
      }
    }
  }
  .skills_section {
    display: flex;
    text-align: center;
    justify-content: space-between;
    padding: 94px 0px 0px 0px;
    margin: 0px;
    flex-wrap: wrap;
    li {
      width: 230px;
      height: 235px;
      background: url("../images/Cruxito-Section-3a.png") no-repeat center;
      display: flex;
      align-items: center;
      justify-content: center;
      &:nth-child(2) {
        background: url("../images/Cruxito-Section-3b.png") no-repeat center;
      }
      &:nth-child(3) {
        background: url("../images/Cruxito-Section-3c.png") no-repeat center;
      }
      &:nth-child(4) {
        background: url("../images/Cruxito-Section-3d.png") no-repeat center;
      }
      .content {
        max-width: 250px;
      }
    }
    h4 {
      font-family: $primaryFont;
      color: $baseColor;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 14px;
    }
    p {
      font-family: $secondaryFont;
      color: $baseColor;
      font-size: 14px;
      font-weight: 400;
      margin: 0px;
    }
    @media screen and (max-width: 990px) {
      width: 100%;
      justify-content: center;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      padding-top: 30px;
      li {
        width: 50%;
        margin-bottom: 20px;
      }
    }
    @media screen and (max-width: 499px) {
      width: 100%;
      li {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 40px 0;
    .banner_section .banner_content p {
      font-size: 16px;
    }
  }
}

.story_section {
  .container {
    display: flex;
    align-items: center;
    figure {
      width: 55%;
      img {
        max-width: 100%;
      }
    }
    .banner_content {
      width: 45%;
      h3 {
        font-family: $primaryFont;
        color: $neutralColor;
        font-size: 42px;
        font-weight: 400;
        margin-bottom: 21px;
      }
      p {
        font-family: $secondaryFont;
        color: $baseColor;
        font-size: 18px;
        font-weight: 400;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
      padding-top: 30px;
      padding-bottom: 30px;
      figure {
        width: 100%;
      }
      .banner_content {
        width: 100%;
        h3 {
          font-size: 28px;
          margin-bottom: 10px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
}

.who_section {
  background: transparent linear-gradient(99deg, #e8fbf5 0%, #d6ecf8 100%);
  padding: 66px 0px 33px 0px;
  .container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      @include Title;
    }
    p {
      font-family: $secondaryFont;
      color: $baseColor3;
      font-size: 18px;
      font-weight: 400;
      width: calc(100% - 300px);
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      p {
        width: 100%;
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }
}

.experiences_section {
  padding: 60px 0px 90px 0px;
  .experiences_container {
    max-width: 1149px;
    margin: 0 auto;
    h2 {
      @include Title;
      text-align: center;
      margin: 0px;
    }
    .companies {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 50px 10% 40px;
      margin: 0px;
      li {
        font-family: $secondaryFont;
        font-size: 26px;
        color: $baseColor;
        font-weight: 700;
        padding: 0 15px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }
    .experiences_list {
      display: flex;
      justify-content: space-between;
      padding: 0px;
      margin: 0px;
      flex-wrap: wrap;
      li {
        .content {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 99;
          background: url("../images/Cruxito-Section-6a.svg") no-repeat center;
          width: 250px;
          height: 203px;
          background-size: 100% 100%;
          span {
            font-family: $secondaryFont;
            font-size: 120px;
            color: $neutralColor;
            font-weight: 800;
          }
          label {
            font-family: $secondaryFont;
            font-size: 22px;
            color: $baseColor;
            font-weight: 400;
            width: 145px;
            margin: 0px 0px 0px 8px;
          }
        }
        &:nth-child(2) {
          .content {
            background: url("../images/Cruxito-Section-6b.svg") no-repeat center;
            width: 250px;
            height: 203px;
            background-size: 100% 100%;
          }
        }
        &:nth-child(3) {
          .content {
            background: url("../images/Cruxito-Section-6c.svg") no-repeat center;
            width: 244px;
            height: 203px;
            background-size: 100% 100%;
            span {
              margin-left: -30px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      .companies {
        justify-content: center;
        padding: 20px 0;
        li {
          padding: 0 10px;
          font-size: 18px;
        }
      }
      .experiences_list {
        justify-content: center;
        li {
          margin-bottom: 20px;
          .content {
            span {
              font-size: 80px;
            }
            label {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }
}

.our_partners {
  padding: 40px 0px 30px 0px;
  background: transparent linear-gradient(125deg, #e8fbf5 0%, #d6ecf8 100%);
  .experiences_container {
    .companies {
      justify-content: center;
      align-items: center;
      padding: 30px 10% 20px;
      li {
        img {
          max-height: 70px;
        }
        &.brandbar {
          img {
            width: 200px;
          }
        }
      }
    }
  }
}
.guidence_section {
  background: url("../images/Cruxito-Section-7-with-Background.png") no-repeat
    center top;
  padding: 140px 0px;
  background-size: cover;
  .container {
    h2 {
      @include Title;
      margin-bottom: 27px;
    }
    p {
      font-family: $secondaryFont;
      color: $baseColor;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 27px;
      max-width: 326px;
    }
    input {
      height: 55px;
      width: 283px;
      padding: 0px 17px;
      font-family: serif;
      font-family: $secondaryFont;
      color: #767676;
      font-size: 16px;
      font-weight: 400;
      border: 0px;
      &:focus-visible {
        outline: none;
      }
    }
    .emails {
      display: flex;
      margin-bottom: 27px;
    }
    button {
      padding: 0px 10px;
      background: transparent linear-gradient(320deg, #4114ad 0%, #ed6c85 100%);
      border-radius: 8px;
      color: $white;
      font-family: $secondaryFont;
      font-size: 16px;
      font-weight: 700;
      border-width: 0px;
      margin-left: 5px;
    }
    label {
      font-family: $secondaryFont;
      color: $baseColor3;
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      text-align: left;
    }
  }
  @media screen and (max-width: 999px) {
    background: #e4f8f6;
    padding: 50px 0;
    .container {
      text-align: center;
      p {
        max-width: 100%;
        text-align: center;
      }
      .emails {
        justify-content: center;
        margin-bottom: 0;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      input {
        width: 100%;
      }
      button {
        font-size: 12px;
        line-height: 16px;
        white-space: nowrap;
      }
    }
  }
}

.development_section {
  padding: 41px 0px 113px 0px;
  background: transparent linear-gradient(126deg, #fbfefd 0%, #f4fafd 100%);
  .container {
    max-width: 1149px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: end;
    justify-content: space-between;
    h3 {
      @include Title;
      margin-bottom: 23px;
    }
    h4 {
      font-family: $primaryFont;
      font-size: 24px;
      color: $baseColor;
      font-weight: 400;
      margin-bottom: 19px;
    }
    p {
      font-family: $secondaryFont;
      font-size: 18px;
      color: $baseColor;
      font-weight: 400;
    }
    .services_list {
      width: calc(100% - 665px);
      padding-left: 50px;
      ul {
        position: static;
        display: inline-block;
        li {
          font-family: $primaryFont;
          font-size: 24px;
          font-weight: 400;
          color: $baseColor;
          margin-bottom: 56px;
          a {
            font-family: $primaryFont;
            font-size: 24px;
            font-weight: 400;
            color: $baseColor;
            text-decoration: none;
            position: relative;
            cursor: pointer;
            &:hover {
              color: $secondaryColor;
              &:before {
                content: "";
                height: 53px;
                width: 29px;
                position: absolute;
                top: -15px;
                left: -47px;
                background: url("../images/Cruxito-Section-8-What-We-Do-Arrow.png")
                  no-repeat left center;
              }
            }
            &:last-child {
              margin-bottom: 0px;
            }
          }
          .sevices_info {
            position: absolute;
            left: 82px;
            bottom: 6px;
            display: none;
            height: 283px;
            width: 545px;
            align-items: center;
            justify-content: center;
            background: $white;
            border-radius: 0 0 30px 30px;
            .sevices_cnt {
              width: 375px;
              margin: 0 auto;
              text-align: center;
              h4 {
                font-family: $primaryFont;
                font-size: 24px;
                color: $baseColor;
                font-weight: 400;
                margin-bottom: 19px;
              }
              p {
                font-family: $secondaryFont;
                font-size: 18px;
                color: $baseColor;
                font-weight: 400;
              }
            }
          }
          &:nth-child(1) {
            .sevices_info {
              display: flex;
            }
          }
          &:hover {
            .sevices_info {
              display: flex;
            }
            a {
              color: $secondaryColor;
              &:before {
                content: "";
                height: 53px;
                width: 29px;
                position: absolute;
                top: -15px;
                left: -47px;
                background: url("../images/Cruxito-Section-8-What-We-Do-Arrow.png")
                  no-repeat left center;
              }
            }
          }
        }
        &:not(:hover) {
          li:nth-child(1) {
            a {
              color: $secondaryColor;
              &:before {
                content: "";
                height: 53px;
                width: 29px;
                position: absolute;
                top: -15px;
                left: -47px;
                background: url("../images/Cruxito-Section-8-What-We-Do-Arrow.png")
                  no-repeat left center;
              }
            }
          }
        }
      }
    }

    .what_we_do {
      width: 664px;
      height: 632px;
      background: url("../images/Development-banner.png") no-repeat center
        bottom;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background-size: 100% auto;
      .content {
        max-width: 372px;
        text-align: center;
        margin-bottom: 30px;
      }
    }
    @media screen and (max-width: 1200px) {
      .what_we_do {
        width: 500px;
        height: 475px;
        .content {
          margin-bottom: 5px;
          h3 {
            margin-bottom: 10px;
          }
          h4 {
            margin-bottom: 10px;
          }
        }
      }
      .services_list {
        width: calc(100% - 500px);
        ul li {
          .sevices_info {
            height: 215px;
            width: 409px;
            left: 65px;
            bottom: 3px;
            .sevices_cnt {
              h3 {
                margin-bottom: 8px;
              }
              h4 {
                margin-bottom: 5px;
              }
              p {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      .what_we_do {
        width: 325px;
        height: 310px;
        margin: 0 auto;
        .content {
          max-width: 225px;
          margin-bottom: 0px;
          h3 {
            margin-bottom: 5px;
            font-size: 24px;
          }
          h4 {
            margin-bottom: 5px;
            font-size: 17px;
            line-height: 18px;
          }
          p {
            margin-bottom: 10px;
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
      .services_list {
        width: 100%;
        padding: 0px;
        ul {
          display: flex;
          overflow: auto;
          padding: 0;
          li {
            margin-bottom: 10px;
            padding: 0 10px;
            a {
              font-size: 18px;
              white-space: nowrap;
              &:before {
                display: none !important;
              }
            }
            .sevices_info {
              height: 138px;
              width: 264px;
              left: calc(50% - -5px);
              transform: translateX(-50%);
              bottom: 4px;
              .sevices_cnt {
                h3 {
                  margin-bottom: 5px;
                  font-size: 24px;
                }
                h4 {
                  margin-bottom: 5px;
                  font-size: 17px;
                  line-height: 18px;
                }
                p {
                  margin-bottom: 10px;
                  font-size: 13px;
                  line-height: 18px;
                }
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 350px) {
      .what_we_do {
        width: 300px;
      }
      .services_list ul li .sevices_info {
        width: 230px;
        .sevices_cnt {
          h3 {
            font-size: 20px;
          }
          h4 {
            font-size: 16px;
            line-height: 16px;
          }
          p {
            font-size: 11px;
            line-height: 16px;
            margin: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 30px 0 10px;
  }
}

.our_work_style {
  padding: 100px 0px 70px 0px;
  background: transparent linear-gradient(125deg, #e8fbf5 0%, #d6ecf8 100%);
  .container {
    h2 {
      @include Title;
      margin-bottom: 35px;
      text-align: center;
    }

    .our_features {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;
      li {
        width: 50%;
        padding: 0 15px 30px 15px;
        .content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 21px 20px;
          background-color: $white;
          box-shadow: 0px 4px 10px #00000029;
          border: 1px solid #5e7fb133;
          border-radius: 40px;
          height: 100%;
        }
        .description {
          width: 65%;
          padding: 0 0 0 20px;
        }
        span {
          display: inline-block;
          width: 35%;
          max-width: 180px;
          img {
            width: 100%;
          }
        }
        h4 {
          font-family: $primaryFont;
          color: $baseColor;
          font-size: 24px;
          font-weight: 400;
        }
        p {
          font-family: $secondaryFont;
          color: $baseColor;
          font-size: 18px;
          font-weight: 400;
        }
        @media screen and (max-width: 767px) {
          margin-bottom: 20px;
          width: 100%;
          padding: 0;
          .content {
            flex-direction: column;
            align-items: flex-start;
            border-radius: 18px;
            .description {
              width: 100%;
              padding: 20px 0 0 0;
              h4 {
                font-size: 18px;
              }
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 30px 0 10px;
  }
}

.success_stories {
  padding: 80px 0px;
  .container {
    max-width: 920px;
    margin: 0 auto;
    h2 {
      @include Title;
      margin-bottom: 32px;
      text-align: center;
    }
    .line {
      display: block;
      border: 1px solid $baseColor4;
      margin-bottom: 64px;
      @media screen and (max-width: 575px) {
        display: none;
      }
    }
    .color-box {
      display: block;
      width: 400px;
      height: 400px;
      background-color: #f4fafd;
      border-radius: 40px;
      overflow: hidden;
      margin: 0px;
      img {
        width: 100%;
      }
    }
    p {
      font-family: $secondaryFont;
      font-size: 18px;
      color: $baseColor3;
      font-weight: 400;
      margin-bottom: 40px;
    }
    .banner_content {
      display: flex;
      // align-items: center;
      justify-content: space-between;
    }
    .content {
      width: calc(100% - 400px);
      padding-left: 40px;
    }
    .labels {
      font-family: $secondaryFont;
      color: #494c53;
      font-size: 18px;
      font-weight: 400;
      span {
        font-size: 14px;
        display: block;
      }
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      display: none;
    }
    .swiper-button-next {
      background: url("../images/right_arrow.svg") no-repeat center;
    }
    .swiper-button-prev {
      background: url("../images/left_arrow.svg") no-repeat center;
    }
    .swiper-button-next,
    .swiper-button-prev {
      opacity: 1;
      &.swiper-button-disabled {
        opacity: 0.5;
        display: block;
      }
    }
    @media screen and (max-width: 767px) {
      .banner_content {
        flex-direction: column;
        .color-box {
          width: 300px;
          max-width: 100%;
          height: 300px;
          margin: 0 auto;
        }
        .content {
          text-align: center;
          width: 100%;
          padding: 30px 0 0 0;
          p {
            font-size: 16px;
            margin-bottom: 15px;
          }
        }
      }
      .swiper_cnt .swiper {
        padding-bottom: 50px;
        .swiper-button-next {
          top: inherit;
          bottom: 0px;
          left: 50%;
        }
        .swiper-button-prev {
          top: inherit;
          bottom: 0px;
          right: 50%;
          left: inherit;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }
  .swiper_cnt {
    position: relative;
    .swiper {
      position: static;
      .swiper-button-prev {
        left: -40px;
      }
      .swiper-button-next {
        right: -40px;
      }
    }
    @media screen and (max-width: 1140px) {
      padding: 0 10px;
      .swiper {
        .swiper-button-prev {
          left: 0px;
        }
        .swiper-button-next {
          right: 0px;
        }
      }
    }
  }
}

.contact-us_section {
  background: transparent linear-gradient(109deg, #fbfefd 0%, #f4fafd 100%);
  padding: 80px 0px 66px 0px;
  .container {
    display: flex;
    justify-content: space-between;
  }
  h2 {
    font-family: $primaryFont;
    font-size: 24px;
    color: $baseColor;
    font-weight: 400;
    margin-bottom: 27px;
  }
  .description {
    font-family: $secondaryFont;
    font-size: 22px;
    font-weight: 400;
    color: $baseColor;
    max-width: 342px;
    span {
      font-family: $secondaryFont;
      font-size: 22px;
      font-weight: 700;
      color: $secondaryColor;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 990px) {
      flex-direction: column;
    }
  }
  .details {
    width: 100%;
    max-width: 340px;
    label {
      display: block;
      font-family: $secondaryFont;
      font-size: 14px;
      font-weight: 400;
      color: $baseColor4;
      margin-bottom: 0px;
    }
    a {
      font-family: $secondaryFont;
      font-size: 22px;
      font-weight: 400;
      color: $baseColor;
      margin-bottom: 20px;
      display: block;
      text-decoration: none;
    }
  }
  .form_section {
    width: 370px;
    input {
      height: 34px;
      font-family: $secondaryFont;
      font-size: 14px;
      font-weight: 400;
      color: $baseColor4;
      margin-bottom: 20px;
      padding: 0px 13px;
      border: 1px solid #5e7fb133;
      border-radius: 3px;
      background-color: $white;
      width: 100%;

      &:focus-visible {
        outline: none;
      }
    }
    select {
      width: 100%;
      height: 34px;
      font-family: $secondaryFont;
      font-size: 14px;
      font-weight: 400;
      color: $baseColor4;
      margin-bottom: 20px;
      padding: 0px 13px;
      border: 1px solid #5e7fb133;
      border-radius: 3px;
      background-color: $white;
      &:focus-visible {
        outline: none;
      }
    }
    textarea {
      height: 100px;
      width: 100%;
      font-family: $secondaryFont;
      font-size: 14px;
      font-weight: 400;
      color: $baseColor4;
      margin-bottom: 15px;
      padding: 5px 13px;
      border: 1px solid #5e7fb133;
      border-radius: 3px;
      background-color: $white;
      &:focus-visible {
        outline: none;
      }
    }
    button {
      @include primaryBtn;
      border: 0px;
    }
    ul {
      padding: 0px;
      margin: 0px;
      li {
        &.twocol {
          display: flex;
          justify-content: space-between;
          input {
            width: calc(50% - 10px);
          }
        }
        .terms_check {
          display: flex;
          margin-bottom: 20px;
          input {
            width: 20px;
            height: 28px;
            min-width: 20px;
          }
          label {
            width: calc(100% - 20px);
            padding-left: 5px;
            cursor: pointer;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 400;
            color: #1b1b1b;
          }
        }
      }
    }
  }
  .contact_section {
    width: calc(100% - 370px);
  }
  @media screen and (max-width: 767px) {
    padding: 30px 0;
    .container {
      flex-direction: column-reverse;
      .form_section {
        width: 100%;
        padding-bottom: 30px;
      }
      .contact_section {
        width: 100%;
        h2 {
          margin-bottom: 15px;
        }
        .description {
          max-width: 100%;
          font-size: 16px;
          span {
            font-size: 16px;
          }
        }
        .details {
          a {
            font-size: 20px;
          }
        }
      }
    }
  }
  &.contactpage {
    padding: 80px 0px 0px 0px;
    .container {
      display: block;
    }
    .contact_section {
      width: 100%;
      text-align: center;
      max-width: 880px;
      margin: 0 auto;
      padding: 50px 0;
      h2 {
        color: #4710b4;
        font-size: 42px;
        font-weight: 400;
      }
      .content {
        .description {
          max-width: 100%;
          width: 100%;
          @media screen and (max-width: 767px) {
            br {
              display: none;
            }
          }
        }
      }
    }
    .contact_form {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      max-width: 900px;
      margin: 0 auto;
      .details {
        width: 50%;
        position: sticky;
        top: 80px;
        ul {
          padding: 0px;
          margin: 20px 0 0 0;
          li {
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            span {
              width: 55px;
              height: 55px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #ddd;
              img {
                width: 24px;
                opacity: 0.6;
              }
            }
            .list-item {
              padding-left: 10px;
              a {
                margin: 0;
              }
              span{
                width: auto;
                height: auto;
                border: none;
                font-size: 16px;
              }
              a{
                font-size: 16px;
              }
            }
          }
        }
      }
      .form_section {
        background: $white;
        padding: 20px;
        border-radius: 16px 16px 0 0;
        width: 50%;
        h2 {
          margin-bottom: 10px;
          font-size: 18px;
        }
        input {
          height: 45px;
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        .details {
          width: 100%;
          position: static;
        }
        .form_section {
          width: 100%;
          ul li.twocol {
            display: block;
            input {
              width: 100%;
            }
          }
        }
      }
    }
    .map {
      width: 100%;
      iframe {
        width: 100%;
        height: 400px;
        display: block;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 50px 0;
      .contact_section {
        padding-bottom: 0px;
        h2 {
          font-size: 32px;
        }
      }
      .contact_form {
        .details a {
          font-size: 18px;
        }
      }
    }
  }
}

footer {
  padding: 22px 0px 0;
  background: transparent linear-gradient(103deg, #e8fbf5 0%, #d6ecf8 100%);
  .container {
    .footer-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(94, 127, 177, 0.35);

      .navigations {
        display: flex;
        flex-direction: column;
        a {
          font-family: $secondaryFont;
          font-size: 14px;
          font-weight: 400;
          color: $secondaryColor;
          text-decoration: underline;
        }
        span {
          display: inline-block;
          margin-top: 15px;
          img {
            max-height: 70px;
          }
        }
      }
      .logo {
        font-family: $primaryFont;
        font-size: 28px;
        color: $baseColor2;
        font-weight: 400;
        img {
          display: block;
          width: 180px;
        }
      }
      .email_sec {
        label {
          font-family: $secondaryFont;
          font-size: 14px;
          font-weight: 400;
          color: $secondaryColor;
          display: block;
          margin-bottom: 5px;
        }
        .footer_input {
          form{
            display: flex;
            input {
              width: 276px;
              min-height: 40px;
              padding: 0px 16px;
              font-family: $secondaryFont;
              font-size: 14px;
              color: $baseColor4;
              font-weight: 400;
              border: 0px;
              border-radius: 3px;
              &:focus-visible {
                outline: none;
              }
            }
            button {
              padding: 0px 10px;
              background: transparent
                linear-gradient(320deg, #4114ad 0%, #ed6c85 100%);
              border-radius: 8px;
              color: $white;
              font-family: $secondaryFont;
              font-size: 16px;
              font-weight: 700;
              border-width: 0px;
              margin-left: 5px;
            }
            @media screen and (max-width: 767px) {
              input {
                width: 100%;
              }
              button {
                white-space: nowrap;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .footer_bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      height: 60px;
      .social_networks {
        display: flex;
        padding: 0px;
        margin: 0px;
        &.wauto {
          li {
            width: auto;
            a {
              color: #9353fa;
            }
          }
        }
        li {
          width: 18px;
          margin-right: 27px;
          display: block;
          img {
            width: 100%;
          }
          &:last-child {
            margin-right: 0px;
          }
          @media screen and (max-width: 767px) {
            margin: 0 5px;
          }
        }
      }
      label {
        font-family: $secondaryFont;
        font-size: 12px;
        color: #494c53;
        font-weight: 300;
      }
      @media screen and (max-width: 767px) {
        height: auto;
        flex-direction: column;
        padding: 20px 0;
        .social_networks {
          margin-bottom: 20px;
        }
      }
    }
    @media screen and (max-width: 991px) {
      .footer-head {
        flex-direction: column-reverse;
        .email_sec {
          margin-bottom: 20px;
          width: 100%;
        }
        .navigations {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          a {
            margin: 0 10px !important;
          }
        }
      }
    }
  }
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.disclaimer {
  background: rgba(#ff9d3a, 0.15);
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: 20px;
  p {
    font-family: $secondaryFont;
    font-weight: 500;
    font-size: 14px;
    color: $baseColor;
    margin: 0;
  }
}

.service-offerings {
  background: transparent linear-gradient(110deg, #e8faf5 0%, #d6ebf7 100%) 0%
    0% no-repeat padding-box;
  padding: 80px 0 0 0;
  .services-cnt {
    padding: 50px 0 0 0;
    .service-block {
      background: $white;
      height: 100%;
      box-shadow: 0px 0px 10px -3px #00000029;
      border-radius: 40px 40px 0px 0px;
      padding: 60px 40px 0 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h3 {
        font-family: $primaryFont;
        font-size: 28px;
        color: $neutralColor;
        line-height: 30px;
        padding: 0;
        margin: 0;
      }
      ul {
        margin: 20px 0;
        padding: 0;
        li {
          margin: 0;
          padding: 0;
          a {
            font-family: $primaryFont;
            font-size: 22px;
            color: $baseColor;
            line-height: 44px;
            padding: 0 0 0 25px;
            margin: 0;
            text-decoration: none;
            display: block;
            &:hover {
              color: #9353fa;
              background: url("../images/arrow.png") no-repeat center left;
              background-size: 16px auto;
            }
          }
        }
      }
      img {
        display: block;
        max-width: 80%;
        margin: 0 auto -60px;
      }
      @media screen and (max-width: 1400px) {
        h3 {
          font-size: 22px;
        }
        ul {
          margin: 15px 0;
          li {
            a {
              font-size: 18px;
              line-height: 34px;
              padding: 0 0 0 16px;
              background-size: auto 16px !important;
            }
          }
        }
      }
      @media screen and (max-width: 1199px) {
        border-radius: 20px;
        padding: 35px 40px 0 30px;
        h3 {
          font-size: 18px;
        }
        ul li a {
          font-size: 18px;
        }
      }
      @media screen and (max-width: 991px) {
        margin-bottom: 15px;
        border-radius: 15px;
        padding: 20px;
        margin-bottom: 15px;
        img {
          margin: 0 auto;
        }
      }
      @media screen and (max-width: 767px) {
        height: auto;
        h3 {
          font-size: 24px;
          line-height: 30px;
        }
        ul {
          margin: 10px 0 20px 0;
          li {
            a {
              font-size: 14px;
              line-height: 28px;
              background-size: 10px auto !important;
              padding: 0 0 0 18px;
            }
          }
        }
        img {
          margin: 0 auto;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 40px 0 0 0;
    .services-cnt {
      padding: 10px 0 0 0;
    }
  }
}
.service-banner {
  background: transparent linear-gradient(62deg, #e8faf5 0%, #d6ebf7 100%) 0% 0%
    no-repeat padding-box;
  width: 100%;
  // height: 100vh;
  padding-top: 80px;
  &:before {
    content: "";
    background: url("../images/service-banner.png") no-repeat 0 0;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 80px;
    background-size: cover;
  }
  .container {
    position: relative;
    z-index: 99;
  }
  .service-banner-cnt {
    text-align: center;
    padding-top: 100px;
    h4 {
      @include Title;
      margin-bottom: 30px;
    }
    p {
      @include Des;
    }
  }
  .service-label {
    color: $neutralColor;
    background: $white;
    height: 44px;
    min-width: auto;
    padding: 0 20px 0 0;
    border-radius: 25px;
    font-family: $secondaryFont;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px;
    a {
      border-right: 1px solid #ddf1f5;
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: 10px;
      border-radius: 25px 0 0 25px;
      padding-left: 3px;
      width: 50px;
      justify-content: center;
      &:hover {
        background: #fafafa;
      }
      img {
        display: block;
      }
    }
  }
  .banner-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    padding: 40px 0;
    .service-banner-text {
      max-width: 625px;
      p {
        color: $primaryColor;
        font-family: $secondaryFont;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        margin: 0;
        a {
          color: $primaryColor;
        }
        strong {
          font-weight: 600;
          a {
            font-weight: 600;
          }
        }
      }
    }
    figure {
      img {
        max-width: 100%;
      }
    }
    @media screen and (max-width: 14002px) {
      .service-banner-text {
        p {
          font-size: 28px;
          line-height: 42px;
        }
      }
    }
    @media screen and (max-width: 992px) {
      .service-banner-text {
        p {
          font-size: 20px;
          line-height: 36px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .service-banner-cnt {
      padding-top: 40px;
      .banner-info {
        padding-bottom: 0px;
        .service-banner-text {
          p {
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
    }
  }
}

.service-features {
  padding: 100px 0;
  .features-sec {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    h2 {
      max-width: 500px;
      color: $primaryColor;
      font-family: $primaryFont;
      font-weight: 400;
      font-size: 34px;
      line-height: 44px;
      position: sticky;
      top: 120px;
    }
    .features-cnt {
      max-width: 700px;
      h6 {
        font-family: $secondaryFont;
        font-size: 22px;
        line-height: 36px;
        font-weight: 600;
        color: $neutralColor;
        margin: 0 0 30px 0;
      }
      .features-list {
        width: 100%;
        .list-item {
          width: 100%;
          display: flex;
          align-items: center;
          box-shadow: 0px 4px 10px #00000029;
          border-radius: 40px;
          padding: 40px 50px;
          margin-bottom: 10px;
          span {
            width: 100px;
            height: 100px;
            min-width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url("../images/shape1.svg") no-repeat 0 0;
            background-size: 100%;
            font-family: $secondaryFont;
            font-weight: 800;
            font-size: 64px;
            color: $neutralColor;
          }
          .features-info {
            padding: 0 0 0 30px;
            h6 {
              font-family: $secondaryFont;
              font-weight: 400;
              font-size: 24px;
              line-height: 32px;
              color: $baseColor;
              margin: 0 0 20px 0;
            }
            p {
              @include Des;
              margin: 0;
              a {
                color: $secondaryColor;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 1400px) {
      h2 {
        font-size: 34px;
        line-height: 44px;
      }
      .features-cnt {
        h6 {
          font-size: 18px;
          line-height: 26px;
        }
        .features-list {
          .list-item {
            padding: 30px 40px;
            border-radius: 30px;
            .features-info h6 {
              font-size: 22px;
              line-height: 28px;
              margin: 0 0 15px 0;
            }
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      .features-cnt {
        .features-list {
          .list-item {
            display: block;
            padding: 20px;
            border-radius: 20px;
            span {
              width: 60px;
              height: 60px;
              min-width: 60px;
              font-size: 30px;
            }
            .features-info {
              padding: 20px 0 0 0;
              h6 {
                margin: 0 0 5px 0;
                font-size: 18px;
                line-height: 22px;
              }
              p {
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    padding: 50px 0;
    .features-sec {
      h2 {
        font-size: 24px;
        line-height: 36px;
      }
      .features-cnt {
        padding-top: 30px;
        h6 {
          margin: 0 0 15px 0;
        }
      }
    }
  }
}

.process {
  background: transparent linear-gradient(118deg, #e8faf5 0%, #d6ebf7 100%) 0%
    0% no-repeat padding-box;
  padding: 100px 0;
  .process-cnt {
    text-align: center;
    .title {
      margin-bottom: 20px;
    }
    .des {
      line-height: 24px;
      margin-bottom: 40px;
    }
  }
  .col-12 {
    padding-bottom: 30px;
  }
  .process-flow {
    box-shadow: 0px 4px 10px #00000029;
    border-radius: 40px;
    padding: 50px;
    background: $white;
    min-height: 100%;
    text-align: left;
    h6 {
      font-family: $primaryFont;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: $baseColor;
      margin: 0 0 20px 0;
    }
    ul {
      padding: 0px;
      margin: 0px;
      list-style-type: none;
      li {
        background: url("../images/checked.png") no-repeat 0 8px;
        @include Des;
        padding: 0 0 0 30px;
        a {
          color: $secondaryColor;
        }
        strong {
          font-weight: 600;
        }
      }
    }
    @media screen and (max-width: 1400px) {
      padding: 35px;
      h6 {
        font-size: 20px;
        line-height: 24px;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 25px;
      border-radius: 14px;
      h6 {
        margin: 0 0 10px 0;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    padding: 50px 0;
  }
  @media screen and (max-width: 767px) {
    .col-12 {
      padding-bottom: 15px;
    }
  }
}
// .why-choose-us{
//   background-color: $white;
//   padding: 100px 0;
//   .proces-cnt {
//     text-align: center;
//     .title {
//       margin-bottom: 20px;
//     }
//     p{
//       max-width: 950px;
//     text-align: center;
//     margin: 0px auto;
//     }
//   }
// }

.development-process {
  background-color: $white;
  padding: 100px 0;
  .proces-cnt {
    text-align: center;
    .title {
      margin-bottom: 20px;
    }
    p {
      max-width: 950px;
      text-align: center;
      margin: 0px auto;
    }
  }
}

.details-wrap {
  .details-sec {
    display: block;
    background: transparent linear-gradient(103deg, #e8faf5 0%, #d6ebf7 100%) 0%
      0% no-repeat padding-box;
    padding: 100px 0;
    &.pad0 {
      padding: 0px;
    }
    .details-cnt {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .details-text {
        max-width: 618px;
        @media screen and (max-width: 1400px) {
          padding: 40px 0;
        }
        h3 {
          @include Title;
          font-size: 48px;
          line-height: 58px;
          margin-bottom: 30px;
        }
        p {
          @include Des;
        }
        ul {
          padding: 0 0 0 20px;
          margin: 0;
          li {
            padding: 0;
            margin: 0;
            @include Des;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 15px;
            list-style-type: disclosure-closed;
            a {
              color: $secondaryColor;
            }
          }
        }
        .how-it-works {
          padding-top: 20px;
          h3 {
            font-size: 26px;
            color: $baseColor2;
            font-weight: 700;
            margin: 0 0 15px 0;
            line-height: 30px;
          }
          ol {
            padding: 0;
            margin: 0;
            li {
              background: transparent
                linear-gradient(103deg, #e8faf5 0%, #d6ebf7 100%) 0% 0%
                no-repeat padding-box;
              padding: 15px 20px;
              border-radius: 10px;
              margin-bottom: 12px;
              @include Des;
              line-height: 28px;
              strong {
                font-weight: 600;
              }
            }
          }
          .how-block {
            box-shadow: 0px 4px 10px #00000029;
            border-radius: 40px;
            padding: 50px;
            background: $white;
            min-height: 100%;
            text-align: left;
            h6 {
              font-family: $primaryFont;
              font-weight: 400;
              font-size: 24px;
              line-height: 29px;
              color: $baseColor;
              margin: 0 0 20px 0;
            }
            ul {
              padding: 0px;
              margin: 0px;
              list-style-type: none;
              li {
                background: url("../images/checked.png") no-repeat 0 8px;
                @include Des;
                padding: 0 0 0 30px;
                strong {
                  font-weight: 600;
                }
              }
            }
            @media screen and (max-width: 1400px) {
              padding: 35px;
              h6 {
                font-size: 20px;
                line-height: 24px;
              }
            }
            @media screen and (max-width: 767px) {
              padding: 25px;
              border-radius: 14px;
              h6 {
                margin: 0 0 10px 0;
              }
            }
          }
        }
        @media screen and (max-width: 1400px) {
          h3 {
            font-size: 44px;
            line-height: 54px;
            margin-bottom: 20px;
          }
          p {
            font-size: 16px;
            line-height: 28px;
          }
        }
      }
      figure {
        display: block;
        margin: 0;
        img {
          max-width: 100%;
        }
        &.mt-60px {
          margin-top: -60px;
        }
      }
    }
    &:nth-child(odd) {
      background: $white;
      .details-cnt {
        flex-direction: row-reverse;
        .details-text {
          padding-left: 40px;
        }
      }
    }
    @media screen and (max-width: 1400px) {
      padding: 40px 0;
    }
  }
  @media screen and (max-width: 767px) {
    .details-sec {
      padding: 20px 0;
      &.pad0 {
        padding-top: 20px;
      }
      .details-cnt {
        flex-direction: column !important;
        .details-text {
          padding: 0 !important;
          h3 {
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 10px;
          }
        }
        figure.mt-60px {
          margin-top: 20px;
        }
      }
    }
  }
}

.schedule-info {
  text-align: center;
  padding: 50px 20px;
  p {
    @include Des;
    max-width: 850px;
    margin: 0 auto;
    a {
      color: $secondaryColor;
    }
  }
}


.page-template-default h1{
  font-family: $primaryFont;
  color: $neutralColor;
}

.blog-post {

  p {
    color: #1b1b1b;
    font-family: Open Sans,sans-serif;
  }
  h3, h4, h5, h6{
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: $baseColor;
    margin: 0px 0px 20px 0px;
    @media screen and (max-width: 767px) {
      font-size: 16px;
      margin: 0px 0px 20px 0px;
    }
  }
  ul {
    padding: 0px;
    margin: 0px 0px 10px 0px;
    list-style-type: none;
    li {
      background: url("../images/checked.png") no-repeat 0 8px;
      @include Des;
      padding: 0 0 0 30px;
      a {
        color: $secondaryColor;
      }
      strong {
        font-weight: 600;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    padding: 35px;
    h6 {
      font-size: 20px;
      line-height: 24px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 25px;
    border-radius: 14px;
    h6 {
      margin: 0 0 10px 0;
    }
  }
}