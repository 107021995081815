@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,800&family=Electrolize&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,800&family=Electrolize&family=Open+Sans:wght@400;700&display=swap");

$primaryFont: "Electrolize", sans-serif;
$secondaryFont: "Open Sans", sans-serif;

@mixin primaryBtn {
  height: 50px;
  padding: 0px 27px;
  background: transparent linear-gradient(289deg, #4114ad 0%, #ed6c85 100%);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $secondaryFont;
  font-size: 22px;
  color: $white;
  font-weight: 700;
  text-decoration: none;
  @media screen and (max-width: 767px) {
    font-size: 14px;
    height: 40px;
    padding: 0px 15px;
  }
}

@mixin Title {
  font-family: $primaryFont;
  color: $primaryColor;
  font-size: 42px;
  font-weight: 400;
  @media screen and (max-width: 1400px) {
    font-size: 34px;
  }
  @media screen and (max-width: 767px) {
    font-size: 26px;
  }
}

@mixin Des {
  font-family: $secondaryFont;
  color: $baseColor;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  @media screen and (max-width: 1400px) {
    font-size: 16px;
    line-height: 26px;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
}
